import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="minio"
    name="Minio"
    shortDescription="Store your backups on MinIO private/public cloud. 100% self-hosted S3-compatible object storage on your own cloud infrastructure"
    website={"https://minio.io/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="minio-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Minio')}
    metaDescription={meta.description.replace('##provider##', 'Minio')}
  >
    <IntegrationStorageContent
      keyRef="minio"
      service="Minio"
      provider="Minio"
      contentBlocks={[{title: 'Store your backups on your SFTP server.', text: (<>
          Connect SimpleBackups to your SFTP server and define it as a backup destination.<br/>
          Your backups will be stored on your SFTP server and SimpleBackups we make sure they always run on time.
        </>)}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
